import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ProteinPurificationChromatography from '../../components/ProteinPurificationChromatography'

const ProteinPurificationPage = () => {
  return (
    <Page>
      <SEO
        title="Protein Purification Workstation (Chromatography) | New Opentrons OT-2 Workstations"
        description="Workstation includes the OT-2 robot, modules, and consumables needed to run your Proteomic assays.  Supports column chromatography and bead-based protein purification."
      />
      <ProteinPurificationChromatography />
    </Page>
  )
}
export default ProteinPurificationPage
