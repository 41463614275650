// @flow

import * as React from 'react'
import cx from 'classnames'
import {ApplicationBadge, ShopItem, SavingsBadge} from '../../ui-components'

import * as styles from './ProteinPurification.module.css'
import {PRICES, SAVINGS} from '../../prices'

type HeroProps = {
  imgSrc: string,
  mobileImgSrc: string,
}

const SHOP_ITEMS = [
  {
    itemName: 'Workstation',
    itemPrice: PRICES.ProteinPurificationChromatographyWorkstation[0],
    itemDiscountPrice: PRICES.ProteinPurificationChromatographyWorkstation[1],
    buttonProps: {
      children: 'Order Workstation',
      className: styles.button,
      linkOutProps: {
        url: 'https://shop.opentrons.com/ot-2-dual-flow-chromatography-protein-purification-workstation',
        gtm: {
          action: 'click',
          category: 'b-workstation',
          label: 'protein-purification-chromatography-buy-button',
        },
      },
      Component: 'a',
    },
  },
]

export default function Hero(props: HeroProps) {
  const {imgSrc, mobileImgSrc} = props
  const BADGE_TEXT = 'Verified Workstation Application'
  const savings = SAVINGS.ProteinPurificationChromatographyWorkstation
  return (
    <div className={styles.bg_color}>
      <section className={cx(styles.container, styles.hero_container)}>
        <div className={styles.mobile_view}>
          <ApplicationBadge className={styles.badge} textSrc={BADGE_TEXT} />
          <h2 className={styles.h2}>
            OT-2 Dual Flow Chromatography Protein Purification Workstation
          </h2>
        </div>
        <div className={styles.image}>
          <SavingsBadge savings={savings} />
          <img src={imgSrc} alt="" />
        </div>
        <div className={styles.mobile_image}>
          <SavingsBadge savings={savings} />
          <img src={mobileImgSrc} alt="" />
        </div>
        <div className={styles.desktop_container}>
          <div className={styles.desktop_view}>
            <ApplicationBadge textSrc={BADGE_TEXT} />
            <h2 className={styles.h2}>
              OT-2 Dual Flow Chromatography Protein Purification Workstation
            </h2>
          </div>
          <p className={styles.p}>
            For small-scale protein purification for up to 96 samples using
            Biotage<sup>®</sup> PhyTip<sup>®</sup> columns
          </p>
          <br />
          <p className={cx(styles.p, styles.italic_style)}>
            The OT-2 Dual Flow Chromatography Protein Purification Workstation
            includes OT-2 Robot and Pipettes, Tips and Labware, and Custom
            Protocol Development
          </p>
          <div className={styles.shop_item_container}>
            {SHOP_ITEMS.map((item, i) => {
              return <ShopItem key={i} {...item} />
            })}
          </div>
        </div>
      </section>
    </div>
  )
}
