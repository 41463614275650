// extracted by mini-css-extract-plugin
export var p = "ProteinPurification-module--p--s+sQo";
export var h2 = "ProteinPurification-module--h2--C90hn";
export var desktop_container = "ProteinPurification-module--desktop_container--aWGYL";
export var h4 = "ProteinPurification-module--h4--qtutz";
export var clean_up_paragraph = "ProteinPurification-module--clean_up_paragraph--V4f+8";
export var application_container = "ProteinPurification-module--application_container--iaXU0";
export var container = "ProteinPurification-module--container--PtOEt";
export var button = "ProteinPurification-module--button--wi22p";
export var mobile_image = "ProteinPurification-module--mobile_image--PSXyA";
export var image = "ProteinPurification-module--image--BvItY";
export var badge = "ProteinPurification-module--badge--nQMxR";
export var bg_color = "ProteinPurification-module--bg_color--EvY2y";
export var hero_container = "ProteinPurification-module--hero_container--y4iwo";
export var content_container = "ProteinPurification-module--content_container--ETeyE";
export var shop_item_container = "ProteinPurification-module--shop_item_container--xIXYf";
export var resource_span = "ProteinPurification-module--resource_span--aIEM1";
export var resource_link_container = "ProteinPurification-module--resource_link_container--G-OLZ";
export var icon = "ProteinPurification-module--icon--6RJVT";
export var icon_image = "ProteinPurification-module--icon_image--GFX7x";
export var resource_link = "ProteinPurification-module--resource_link--O0+Lt";
export var resource_note_container = "ProteinPurification-module--resource_note_container--JAoqQ";
export var bold_style = "ProteinPurification-module--bold_style--lNKrD";
export var italic_style = "ProteinPurification-module--italic_style--RVQ4o";
export var mobile_view = "ProteinPurification-module--mobile_view--tJ1dY";
export var desktop_view = "ProteinPurification-module--desktop_view--JtI2G";
export var grid = "ProteinPurification-module--grid--WA6Re";
export var grid_header = "ProteinPurification-module--grid_header--a+AWD";
export var grid_item = "ProteinPurification-module--grid_item--R6woi";
export var services_table = "ProteinPurification-module--services_table--8xAVJ";
export var workflow_steps = "ProteinPurification-module--workflow_steps--tY5Sb";