// @flow

import * as React from 'react'
import PrePuriticationSvg from './assets/icon_pre_purification.svg'
import PuriticationBindSvg from './assets/icon_purification_bind.svg'
import PuriticationWashSvg from './assets/icon_purification_wash.svg'
import PuriticationEluteSvg from './assets/icon_purification_elute.svg'
import {AppLink, BulletPoint} from '../../ui-components'

import * as styles from './ProteinPurification.module.css'

const PHYNEXUS_COLUMNS = {
  header: 'The following Biotage PhyTip Columns are available for the OT-2',
  items: [
    {
      title: 'C18 300Å',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/c18-mass-spectrometry-desalting-phytip-columns/',
    },
    {
      title: 'C18 90Å',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/c18-mass-spectrometry-phytip-columns/',
    },
    {
      title: 'C4 300Å',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/c4-mass-spectrometry-phytip-columns/',
    },
    {
      title: 'CaptureSelect IgG-Fc Hu',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/captureselect-affinity-bac-phytip-columns/',
    },
    {
      title: 'CaptureSelect IgG-Fc',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/captureselect-affinity-bac-phytip-columns/',
    },
    {
      title: 'CaptureSelect KappaXL',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/captureselect-affinity-bac-phytip-columns/',
    },
    {
      title: 'CaptureSelect IgG-Fc-ms',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/captureselect-affinity-bac-phytip-columns/',
    },
    {
      title: 'Glutathione',
      url: 'https://phynexus.com/products/proteins/recombinant-protein-phytip-columns/gst-tagged-proteins/',
    },
    {
      title: 'IMAC',
      url: 'https://phynexus.com/products/proteins/recombinant-protein-phytip-columns/his-tagged-proteins-purification-imac-nickel-nimac-columns/',
    },
    {
      title: 'Normal Phase 1',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/normal-phase-phytip-columns/',
    },
    {
      title: 'POROS CaptureSelect IgM',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/captureselect-affinity-bac-phytip-columns/',
    },
    {
      title: 'ProPlus & ProPlus LX(MabSelect Sure™)',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/proplus-proplus-lx-mabselect-sure-phytip-columns/',
    },
    {
      title: 'Protein A',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/protein-a/',
    },
    {
      title: 'Protein G',
      url: 'https://phynexus.com/products/proteins/antibody-binding-phytip-columns/protein-g/',
    },
    {
      title: 'Streptavidin',
      url: 'https://phynexus.com/products/proteins/recombinant-protein-phytip-columns/streptavidin-columns-biotin-tagged-molecules/',
    },
    {
      title: 'Strong Anion',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/ion-exchange-phytip-columns/',
    },
    {
      title: 'Strong Cation',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/ion-exchange-phytip-columns/',
    },
    {
      title: 'Weak Anion',
      url: 'https://phynexus.com/products/proteins/chromatography-phytip-columns/ion-exchange-phytip-columns/',
    },
  ],
}

const PhynexTable = () => {
  const {items} = PHYNEXUS_COLUMNS
  return (
    <div className={styles.grid}>
      <div className={styles.grid_header}>
        The following{' '}
        <AppLink url="https://biomolecules.biotage.com/phytip_columns_for_ot2">
          Biotage PhyTip Columns are available for the OT-2
        </AppLink>
      </div>
      {items.map(({title}) => (
        <span key={title} className={styles.grid_item}>
          <span>{title}</span>
        </span>
      ))}
    </div>
  )
}

const workflowBulletPoints = [
  'Reagent plate preparation',
  'Dual flow chromatography using PhyTip columns',
]

export const CONTENT_INFO = (
  <div>
    <p className={styles.p}>
      With the OT-2 Dual Flow Chromatography (DFC) Protein Purification
      Workstation, you can automate your small-scale protein purification and
      proteomics sample processing. This Opentrons Protein Purification
      Workstation uses Biotage PhyTip columns to perform dual flow
      chromatography-based protein purification.
    </p>
    <div className={styles.workflow_steps}>
      <p className={styles.p}>
        The OT-2 DFC Protein Purification Workstation can support the following
        workflow steps:
      </p>
      <BulletPoint names={workflowBulletPoints} />
    </div>
    <PhynexTable />
  </div>
)

export const LINKS = {
  Overview: {
    title: 'Overview',
    scrollId: 'overview',
  },
  WorkstationComponents: {
    title: 'Workstation Components',
    scrollId: 'materials',
  },
  Protocols: {
    title: 'Protocols',
    scrollId: 'protocols',
  },
  ApplicationNotes: {
    title: 'Application Notes',
    scrollId: 'notes',
  },
}

export const MENU_DATA = {
  menuData: {
    links: [
      LINKS.Overview.title,
      LINKS.WorkstationComponents.title,
      LINKS.ApplicationNotes.title,
      LINKS.Protocols.title,
    ],
    linkScrollIds: {
      [LINKS.Overview.title]: LINKS.Overview.scrollId,
      [LINKS.WorkstationComponents.title]: LINKS.WorkstationComponents.scrollId,
      [LINKS.ApplicationNotes.title]: LINKS.ApplicationNotes.scrollId,
      [LINKS.Protocols.title]: LINKS.Protocols.scrollId,
    },
    url: '/cleanupkit/',
    workflow: 'Interested in automating your protein purification protocol?',
  },
  gtmCategory: 'b-workstation',
  protocolGtmLabel: 'schedule-consultation',
  demoGtmLabel: 'schedule-demo',
}

export const SCROLL_MENU_DATA = [
  LINKS.Overview.scrollId,
  LINKS.WorkstationComponents.scrollId,
  LINKS.Protocols.scrollId,
]

export const NOTE_DATA = [
  {
    note: (
      <AppLink
        url={
          'https://insights.opentrons.com/hubfs/Applications/Protein%20purification/Automated%20PhyTip%20Protein%20Purification%20App%20Note.pdf'
        }
      >
        Automated Protein Purification with Biotage Dual Flow Chromatography
        PhyTip Columns on the OT-2
      </AppLink>
    ),
  },
]

export const PROTOCOLS_DATA = {
  description: '',
  hideTable: true,
  hideDisclosures: true,
  bulletListDescription:
    'Check out example protocols below, or browse all protein purification library prep protocols <a style="color: #006fff; font-weight: 600;" href="https://protocols.opentrons.com/categories/Protein%20Purification">here</a>',
  bulletItems: [
    {
      title:
        'Protein purification using Phytip Protein A, ProPlus, ProPlus LX Columns',
      url: 'https://protocols.opentrons.com/categories/Protein%20Purification/Protein%20A%2C%20Pro%20Plus%2C%20Pro%20Plus%20LX%20Columns/',
    },
  ],
}

export const LABWARE_URL = 'https://labware.opentrons.com/'

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'This workflow can be used for the following applications:',
  listBulletpoint: [
    'Screening and optimization of purification conditions',
    'High-throughput protein purification for downstream applications',
    'Capture of macromolecular complexes (protein-protein, protein-DNA)',
    'Enzymatic and chemical lysis',
    'General purpose liquid handling',
    'Reagent distribution',
  ],
}

export const MATERIAL_DESCRIPTION =
  'The OT-2 DFC Protein Purification Workstation includes all the hardware and consumables you need to perform automated protein purification (with the exclusion of the PhyTip columns, which must be purchased from Biotage). It comes with Opentrons’ protocol development service, which provides you with a customized protocol unique to your assay. You’ll also enjoy free access to the Opentrons Protocol Designer, Python API, and Protocol Library.'

export const MATERIAL_DATA = [
  {
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
  },
  {
    imgSrc: require('./assets/P300M.png').default,
    name: 'P300 Multi-Channel Pipette',
  },
  {
    imgSrc: require('./assets/P300S.png').default,
    name: 'P300 Single-Channel Pipette',
  },
  {
    imgSrc: require('./assets/15x15_RackStand.png').default,
    name: '4-in-1 Tube Rack Set',
  },
  {
    imgSrc: require('./assets/300ul_tips.png').default,
    name: 'Opentrons 300 μl Tips Racks (2 x 9600 tips)',
  },
  {
    imgSrc: require('./assets/nest_12well_reservoir.png').default,
    name: 'NEST 12-Well Reservoir 15 ml (50)',
  },
  {
    imgSrc: require('./assets/nest_96_well_plate.png').default,
    name: 'NEST 96-Well Plate Flat (100)',
  },
  {
    name: 'Biotage PhyTip columns sold separately',
  },
  {
    name: 'Custom protocol development (standard turnaround time)',
  },
]

export const WORKFLOW_DESCRIPTION =
  'The Opentrons Protein Purification Workstation supports low- to medium-throughput workflows using column chromatography protein purification. Below is an overview of an Opentrons protein purification assay, information on what the workstations include, an application note featuring this workstation, your protocol development options, and other information you may need for your assay.'

export const WORKFLOW_DATA = [
  {
    imgSrc: PrePuriticationSvg,
    name: 'Pre-Purification',
    content: 'Cell lysate before purification',
  },
  {
    imgSrc: PuriticationBindSvg,
    name: 'Bind',
    content:
      'Add magnetic beads and load them on the OT-2 robot magnet. Process until supernatant is ready to be removed.',
  },
  {
    imgSrc: PuriticationWashSvg,
    name: 'Wash',
    content:
      'Wash off impurities with a wash and/or strip buffer. Process until the protein of interest is released.',
  },
  {
    imgSrc: PuriticationEluteSvg,
    name: 'Elute',
    content: 'Elute desired material.',
  },
]

export const BENEFIT_DATA = {
  benefitList: [
    'Maximum accuracy, precision and reproducibility',
    'Increased hands-free and walk-away time',
    'Best automation ROI',
    'Works with any reagent or bead based product used in Protein Purification',
  ],
  benefitContent:
    'Automating protein purification improves the quality of your assay while reducing errors, assay reruns, and uncontrolled variables.  The Opentrons Protein Purification Workstation provides your assay:',
  imageItalicText: 'Closeup of beads pelleting on the OT-2 magnetic module',
}

export const APPLICATION_DATA_DATA = {
  italicText: `Purification of 20 µL of indexed 16S Metagenomic and ladder samples with 1X and 1.8X bead volumes to show OT-2 size selection on Agilent TapeStation High Sensitivity D1000 ScreenTape®`,
  imgSrc: require('./assets/cleanup-kit-graph-01-1200-px@2x.png').default,
}

export const RESOURCE_NOTE_DATA = [
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
    validationIcon: true,
  },
  {
    resource: 'video',
    contentText: 'Magnetic Module',
    contentLink: 'https://vimeo.com/299671341',
    iconSrc: 'video-sample',
  },
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
  {
    resource: 'protocol',
    contentText: 'Pending new protocol',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
]
